<template>
    <div>
        <ul class="user-ul">
            <a href="javascript:;" v-for="(m, i) in list">
                <li class="list" :key="m.id" @click.stop="goUser(m.user_id)">
                    <img :src="rImg(m.user_head)" :onerror="txImg" class="tx" alt="">
                    <div class="content">
                        <p class="name">{{m.user_name}} <span class="role">【{{aim[m.entertainment]}}】</span></p>
                        <div class="des">
                            <span v-if="m.city">{{m.city}}</span>
                            <span v-if="m.user_sex">{{m.user_sex}}</span>
                            <span v-if="m.user_age">{{m.user_age}}岁</span>
                            <span>{{m.is_online==1? '在线':m.last_login_datetime}}</span>
                        </div>
                    </div>
                </li>
            </a>
        </ul>
        <div class="page-box" v-show="total">
            <el-pagination :pager-count="5" :page-size="20" @current-change="changePage" background layout="prev, pager, next" :total="total"></el-pagination>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                list: [],
                total: 0,
                aim: {
                    0: '娱乐',
                    1: '分享',
                    2: '交友'
                }
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData(1)
        },
        methods: {
            changePage(s) {
                this.getData(s);
            },
            // ...mapMutations([])
            // 获取列表
            async getData(page) {
                let param = {
                    user_id: this.$y_getKey('userid'),
                    types: 2
                };
                param.page = page || 1;
                let {
                    list,
                    total
                } = await this.$y_list("home/Dymanic/myattention", [], param);
                this.list = list;
                if (total != -1) {
                    this.total = total;
                }
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .list {
        padding: 15px;
        border-bottom: 1px solid #eee;
        display: flex;

        .tx {
            width: 50px;
            height: 50px;
            margin-right: 20px;
        }

        .name {
            font-size: 18px;
        }

        .role {
            color: #fd5621;
            font-size: 14px;
        }

        .des {
            color: #555;
            margin-top: 7px;

            span {
                &:after {
                    content: '/';
                    display: inline-block;
                    margin: 0 5px;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
</style>